import logoimg from "../assets/logo-text-white.png";
import "./Header.css";



function Header() {
    return (
        <header className="px-5 md:px-6 lg:px-12 py-4 border-b border-white/10">
            <a rel="noreferrer" href="https://shinsekai.io" target="_blank" className="text-white font-bold text-2xl md:text-3xl">
                <img id="logo-header-book" src={logoimg} alt="logo-shinsekai" />
            </a>
        </header>
    )
}

export default Header
