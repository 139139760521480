import BookContainer from "./components/BookContainer";
import Header from "./components/Header";
import "./App.css"
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />
      <BookContainer />
      <Footer/>
    </>
  )
}

export default App
