import "./Footer.css";
import { FaDiscord,FaInstagram,FaTwitter} from "react-icons/fa";
import React from "react";


function Footer(){
    return(
        <div className="footer-container">
                <div className="social-footer">
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/shinsekaicorp/">
                        <FaInstagram  style={{fill : 'white'}}/>
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://t.co/bUper2SbeI">
                        <FaDiscord  style={{fill : 'white'}}/>
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://twitter.com/shinsekaicorp">
                        <FaTwitter  style={{fill : 'white'}}/>
                    </a>

                </div>
                <div className="footer-text">
                    2022 ShinSekaiCorp | All rights reserved 
                </div>
        </div>
    
    );
}

export default Footer;