import { useMemo } from 'react'
import { useState } from 'react'
import pages from '../pageData'
import "./BookContainer.css";

const btnStyle = 'text-white border border-white/20 px-5 py-2 mb-2 hover:bg-white/5 disabled:cursor-not-allowed disabled:opacity-60 active:bg-white/20'

function BookContainer() {

    const [currentPage, setCurrentPage] = useState(1)

    const totalPages = Object.keys(pages).length

    const getPageData = (pageNumber) => {
        const result = pages[pageNumber]
        return result || null
    }

    const goNextPage = () => {
        const nextPage = currentPage + 1;
        const nextPageData = getPageData(nextPage)
        nextPageData && setCurrentPage(nextPage)
    }

    const goPreviousPage = () => {
        const previousPage = currentPage - 1;
        const previousPageData = getPageData(previousPage)
        previousPageData ? setCurrentPage(previousPage) : setCurrentPage(1)
    }

    const hasPreviousPage = useMemo(() => {
        const previousPage = currentPage - 1;
        const previousPageData = getPageData(previousPage)
        return previousPageData ? true : false
    }, [currentPage])

    const hasNextPage = useMemo(() => {
        const nextPage = currentPage + 1;
        const nextPageData = getPageData(nextPage)
        return nextPageData ? true : false
    }, [currentPage])


    const currentPageData = getPageData(currentPage)

    return (
        <section className="w-fit px-5 md:px-0 mx-auto my-12">
            <div className="text-center mb-8">
                <h1 className=" headerbook text-white text-3xl md:text-4xl font-bold mb-2">FUKIYOSE</h1>
                <p className="text-white/75">{currentPage} / {totalPages}</p>
            </div>

            <div className="flex justify-between items-center">
                <button disabled={!hasPreviousPage} className={btnStyle} onClick={goPreviousPage}>Prev</button>
                <button disabled={!hasNextPage} className={btnStyle} onClick={goNextPage}>Next</button>
            </div>

            <div onClick={goNextPage} className="w-full select-none md:w-[750px] lg:w-[900px] h-auto border border-white/20 p-3 md:p-5 text-white/90">
                <img src={currentPageData.content} alt="Page" className="w-full h-auto object-cover" />
            </div>
        </section>
    )
}

export default BookContainer
