export default {
    1: {
        content: '/img/1.png'
    },
    2: {
        content: '/img/2.png'
    },
    3: {
        content: '/img/3.png'
    },
    4: {
        content: '/img/4.png'
    },
    5: {
        content: '/img/5.png'
    },
    6: {
        content: '/img/6.png'
    },
    7: {
        content: '/img/7.png'
    },
    8: {
        content: '/img/8.png'
    },
    9: {
        content: '/img/9.png'
    },
    10: {
        content: '/img/10.png'
    },
    11: {
        content: '/img/11.png'
    },
    12: {
        content: '/img/12.png'
    },
    13: {
        content: '/img/13.png'
    },
    14: {
        content: '/img/14.png'
    },
    15: {
        content: '/img/15.png'
    },
    16: {
        content: '/img/16.png'
    },
}